import Swiper from 'swiper/swiper-bundle';

$('.js-slider-tiles .swiper').each((i, slider) => {
	const swiper = new Swiper(slider, {
		slidesPerView: 'auto',
		spaceBetween: 12,
		breakpoints: {
			1200: {
				slidesPerView: 3,
				spaceBetween: 35,
			},
		},
	});
});

