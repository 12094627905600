$('.js-search-trigger').on('click', function(event) {
	event.preventDefault();

	$('.popup-search').addClass('is-active');
});

$('.popup-search').on('click', function() {
	$(this).removeClass('is-active');
});

$('.popup-search .popup__content').on('click', function(event) {
	event.stopPropagation();
});

$('.search-form').on('submit', function() {
	$('.popup-search').removeClass('is-active');
})