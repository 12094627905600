$('.accordion__head').on('click',function(){
	$(this)
		.parent()
			.toggleClass('is-active')
			.find('.accordion__body')
			.slideToggle()
			.end()
		.siblings()
			.removeClass('is-active')
			.end()
		.siblings()
			.find('.accordion__body')
			.slideUp()
});
