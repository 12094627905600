import { $document } from '../utils/globals';

let isLoading = false;

$document.on('click', '.js-filters a', function(event) {
	event.preventDefault();

	const url = $(this).attr('href');

	$(this)
		.addClass('current')
		.parent('li')
		.siblings()
		.find('a')
		.removeClass('current');

	$('.js-search-form .search__field').val('');

	crbLoadPosts(url);
})

$document.on('click', '.js-actions a', function(event) {
	event.preventDefault();

	$('.js-search-form .search__field').val('');

	const url = $(this).attr('href');
	crbLoadPosts(url);
})

$('.js-search-form').on('submit', function(event) {
	event.preventDefault();

	$('.js-filters a').removeClass('current');

	const url       = $(this).attr('action');
	const searchVal = $(this).find('.search__field').val();
	const newUrl    = updateQueryStringParameter(url, 'search', searchVal);
	crbLoadPosts(newUrl);
})

function crbLoadPosts(url) {
	if ( isLoading ) {
		return;
	}

	let $postsContainer = $('.js-ajax-container');
	let $postsHolder    = $postsContainer.find('.js-posts-holder');
	let $actions        = $postsContainer.find('.js-actions');
	const $spinner      = $('.loading-screen');

	$.ajax({
		url: url,
		type: "GET",
		beforeSend: function(){
			isLoading = true;
			$spinner.toggleClass('show');
	   },
	}).done( function(response){
		let $newPostsContainer = $('.js-ajax-container', response);
		let $newPostsHolder    = $newPostsContainer.find('.js-posts-holder');
		let $newActions        = $newPostsContainer.find('.js-actions');
		let $newPosts          = $newPostsHolder.children();

		$newPosts.css('display', 'none');
		$postsHolder.replaceWith($newPostsHolder);
		$actions.replaceWith($newActions);

		$newPosts.each(function(i){
			$(this).fadeIn(i*300);
		})

		const $scrollContainer = $('.js-bar').length ? $('.js-bar') : $('.section-blog');

		$('html, body').animate({
			scrollTop: $scrollContainer.offset().top
		}, 600, 'linear');

		isLoading = false;
		$spinner.toggleClass('show');
	})
}

function updateQueryStringParameter(uri, key, value) {
	const re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");

	if (uri.match(re)) {
		let newUrl = uri.replace(re, '$1' + key + "=" + value + '$2');

		if (!value.length) {
			newUrl = newUrl.replace('?' + key + '=', '');
			newUrl = newUrl.replace('&' + key + '=', '');

			if (newUrl.indexOf('?') === -1) {
				newUrl = newUrl.replace('&', '?');
			}
		}

		return newUrl;
	} else {
		let hash = '';
		if (uri.indexOf('#') !== -1) {
			hash = uri.replace(/.*#/, '#');
			uri = uri.replace(/#.*/, '');
		}

		const separator = uri.indexOf('?') !== -1 ? "&" : "?";
		return uri + separator + key + "=" + value + hash;
	}
}