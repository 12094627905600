import { $body } from '../utils/globals';

$('.js-nav-trigger').on('click', function (ev){
	 ev.preventDefault();

	 $body.toggleClass('has-active-nav');
});

$('.menu-item-has-children > a').on('click', function (e) {
	const $menuItem = $(this).parent();

	if (! $menuItem.is('.is-active')) {
		e.preventDefault();

		$menuItem
			.addClass('is-active')
			.siblings()
			.removeClass('is-active')
			.find('is-active')
			.removeClass('is-active');
	}
});
