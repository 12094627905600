/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/footer-links'
import './modules/mobile-nav'
import './modules/init-swiper'
import './modules/accordion'
import './modules/blog-ajax'
import './modules/search-trigger'
